<template>
  <!-- Hero content: will be in the middle -->
  <section class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-4">
          <div class="columns is-multiline login">
            <div class="column is-12 box-auth">
              <div class="login-header">
                <h3 class="is-size-4 login-title">RESET PASSWORD</h3>
              </div>
              <div class="login-body">
                <h3 class="is-size-4">INVALID RESET PASSWORD TOKEN</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null
    };
  },
  methods: {
    processFormLogin(username, password) {
      this.$store
        .dispatch("login", { username, password })
        .then(() => {
          this.success("Login Success");
        })
        .catch(() => {
          this.danger("Username or Password is incorrect");
        });
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  border: 1px solid #d6dee3;
}
.login-header {
  border-bottom: 1px solid #f2f5f9;
  padding: 30px;
}
.login-title {
  color: #333d5d;
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: bold;
}
.login-subtitle {
  color: #b9bbc6;
}
.login-body {
  padding: 30px;
}
.form-group {
  margin-bottom: 1.7rem;
}
</style>
